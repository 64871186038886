import { useEffect, useRef, useState } from "react"
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { useParams } from "react-router-dom"
import { ApiService } from "../../Component/Services/apiservices"
import { formatPublishDate } from '../../Component/Services/moment'
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SeriesOverview from "./components/overview"
import SeriesMatches from "./components/matches"
import SeriesPointTable from "./components/point_table"
import SeriesSquads from "./components/squads"
import SeriesStats from "./components/stats"
import SeriesNews from "./components/news"
import SeriesVenue from "./components/venue"
import TrendingPlayer from "../../Component/Elements/trending_player"
import CurrentSeries from "../../Component/Elements/current_series"
import LatestNews from "../../Component/Elements/latest_news"
import constant from "../../Component/Services/constant"
import { BrowserView, MobileView} from 'react-device-detect';

const SeriesDetail = () => {
    const didMountRef = useRef(true)
    const { series_id } = useParams()
    const [seriesDetail, setSeriesDetail] = useState({})
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('Overview');
    useEffect(() => {
        if (series_id) {
            getSeriesDetail()
        }
    }, [series_id])

    const handleToggleTabs = (tab) => {
        setActiveTab(tab);
    };
    const getSeriesDetail = () => {
        const dataString = {
            series_id: series_id
        }
        setLoading(true)
        ApiService.postData("series/seriesDetail", dataString).then((res) => {
            if (res.status === "success") {
                setSeriesDetail(res.seriesDetail)
                setLoading(false)
            } else {
                setLoading(false)
            }

        })
    }
    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };
    return (
        <>
            <Header></Header>
            <BrowserView>
            <div className="pageSubheader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Breadcrumb>
                                {loading ?
                                    <>
                                        <Breadcrumb.Item href="/"><Skeleton width={40} height={15} /></Breadcrumb.Item>
                                        <Breadcrumb.Item href="/"><Skeleton width={350} height={15} /></Breadcrumb.Item>
                                        <Breadcrumb.Item href="/"><Skeleton width={100} height={15} /></Breadcrumb.Item>
                                    </>
                                    :
                                    <>
                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item href={'/series-detail/'+seriesDetail.series_slug}>{seriesDetail.series_title}</Breadcrumb.Item>
                                        <Breadcrumb.Item active href="/">{activeTab}</Breadcrumb.Item>
                                    </>
                                }
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-gap-small pt-0">
                <div className="container">
                    <div className="row">
                        {loading ?
                            <div className="col-lg-9">
                                <div className="cardBox mb-20">
                                    <div className="cardBoxBody p-25">
                                        <div className="seriesBox">
                                            <figure className="seriesBoxMedia">
                                                <Skeleton width={150} height={200} />
                                            </figure>
                                            <div className="seriesBoxContent">
                                                <p className="mb-1"><Skeleton width={150} height={15} /></p>
                                                <h2 className="title"><Skeleton width={500} height={25} /></h2>
                                                <div className="seriesBoxMeta">
                                                    <ul>
                                                        <li><Skeleton width={70} height={15} /></li>
                                                        <li><Skeleton width={70} height={15} /></li>
                                                        <li><Skeleton width={70} height={15} /></li>
                                                    </ul>
                                                </div>
                                                <div className="seriesBox-date">
                                                    <Skeleton width={150} height={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seriesTabs mb-20">
                                    <ul>
                                        <li><Skeleton width={60} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                    </ul>
                                </div>
                            </div>
                            :
                            <div className="col-lg-9">
                                {seriesDetail && seriesDetail.series_id > 0 && (
                                    <div className="cardBox mb-20">
                                        <div className="cardBoxBody p-25">
                                            <div className="seriesBox">
                                                <figure className="seriesBoxMedia">
                                                    <img src={seriesDetail.series_image} alt={seriesDetail.series_title} onError={handleImageError}></img>
                                                </figure>
                                                <div className="seriesBoxContent">
                                                    <p className="mb-1">{seriesDetail.series_type}</p>
                                                    <h2 className="title">{seriesDetail.series_title}</h2>
                                                    <div className="seriesBoxMeta">
                                                        <ul>
                                                            {seriesDetail.series_matches && (<li>{seriesDetail.series_matches} Matches</li>)}
                                                            {seriesDetail.series_players && seriesDetail.series_players.data && (<li>{seriesDetail.series_players.data.length} Teams</li>)}
                                                        </ul>
                                                    </div>
                                                    <div className="seriesBox-date">
                                                        {formatPublishDate(seriesDetail.series_start_date)} - {formatPublishDate(seriesDetail.series_end_date)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="seriesTabs mb-20">
                                    <ul>
                                        <li className={activeTab === 'Overview' ? 'active' : ''} onClick={() => handleToggleTabs('Overview')} >Overview</li>
                                        <li className={activeTab === 'Matches' ? 'active' : ''} onClick={() => handleToggleTabs('Matches')} >Matches</li>
                                        <li className={activeTab === 'Point Table' ? 'active' : ''} onClick={() => handleToggleTabs('Point Table')} >Point Table</li>
                                        <li className={activeTab === 'Squads' ? 'active' : ''} onClick={() => handleToggleTabs('Squads')} >Squads</li>
                                        <li className={activeTab === 'Stats' ? 'active' : ''} onClick={() => handleToggleTabs('Stats')} >Stats</li>
                                        <li className={activeTab === 'News' ? 'active' : ''} onClick={() => handleToggleTabs('News')} >News</li>
                                        <li className={activeTab === 'Venue' ? 'active' : ''} onClick={() => handleToggleTabs('Venue')} >Venue</li>
                                    </ul>
                                </div>
                                {activeTab === 'Overview' ?
                                    <SeriesOverview seriesDetail={seriesDetail}></SeriesOverview>
                                    :
                                    activeTab === 'Matches' ?
                                        <SeriesMatches seriesDetail={seriesDetail}></SeriesMatches>
                                        :
                                        activeTab === 'Point Table' ?
                                            <SeriesPointTable seriesDetail={seriesDetail}></SeriesPointTable>
                                            :
                                            activeTab === 'Squads' ?
                                                <SeriesSquads seriesDetail={seriesDetail}></SeriesSquads>
                                                :
                                                activeTab === 'Stats' ?
                                                    <SeriesStats seriesDetail={seriesDetail}></SeriesStats>
                                                    :
                                                    activeTab === 'News' ?
                                                        <SeriesNews seriesDetail={seriesDetail}></SeriesNews>
                                                        :
                                                        activeTab === 'Venue' ?
                                                            <SeriesVenue seriesDetail={seriesDetail}></SeriesVenue>
                                                            : null}
                            </div>
                        }
                        <div className="col-lg-3">
                            <div className="seriesSidebar">
                                <TrendingPlayer />
                                <CurrentSeries />
                                <LatestNews />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </BrowserView>
            <MobileView>
            <section className="section-gap-small pt-0">
                <div className="container">
                    <div className="row">
                        {loading ?
                            <div className="col-lg-9">
                                <div className="cardBox mb-20">
                                    <div className="cardBoxBody p-25">
                                        <div className="seriesBox">
                                            <figure className="seriesBoxMedia">
                                                <Skeleton width={150} height={200} />
                                            </figure>
                                            <div className="seriesBoxContent">
                                                <p className="mb-1"><Skeleton width={150} height={15} /></p>
                                                <h2 className="title"><Skeleton width={500} height={25} /></h2>
                                                <div className="seriesBoxMeta">
                                                    <ul>
                                                        <li><Skeleton width={70} height={15} /></li>
                                                        <li><Skeleton width={70} height={15} /></li>
                                                        <li><Skeleton width={70} height={15} /></li>
                                                    </ul>
                                                </div>
                                                <div className="seriesBox-date">
                                                    <Skeleton width={150} height={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="seriesTabs mb-20">
                                    <ul>
                                        <li><Skeleton width={60} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                        <li><Skeleton width={50} height={15} /></li>
                                    </ul>
                                </div>
                            </div>
                            :
                            <div className="col-lg-9">
                                {seriesDetail && seriesDetail.series_id > 0 && (
                                    <div className="cardBox mb-20">
                                        <div className="cardBoxBody p-25">
                                            <div className="seriesBox">
                                                <figure className="seriesBoxMedia">
                                                    <img src={seriesDetail.series_image} alt={seriesDetail.series_title} onError={handleImageError}></img>
                                                </figure>
                                                <div className="seriesBoxContent">
                                                    <p className="mb-1">{seriesDetail.series_type}</p>
                                                    <h2 className="title">{seriesDetail.series_title}</h2>
                                                    <div className="seriesBoxMeta">
                                                        <ul>
                                                            {seriesDetail.series_matches && (<li>{seriesDetail.series_matches} Matches</li>)}
                                                            {seriesDetail.series_players && seriesDetail.series_players.data && (<li>{seriesDetail.series_players.data.length} Teams</li>)}
                                                        </ul>
                                                    </div>
                                                    <div className="seriesBox-date">
                                                        {formatPublishDate(seriesDetail.series_start_date)} - {formatPublishDate(seriesDetail.series_end_date)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="seriesTabs mb-20">
                                    <ul>
                                        <li className={activeTab === 'Overview' ? 'active' : ''} onClick={() => handleToggleTabs('Overview')} >Overview</li>
                                        <li className={activeTab === 'Matches' ? 'active' : ''} onClick={() => handleToggleTabs('Matches')} >Matches</li>
                                        <li className={activeTab === 'Point Table' ? 'active' : ''} onClick={() => handleToggleTabs('Point Table')} >Point Table</li>
                                        <li className={activeTab === 'Squads' ? 'active' : ''} onClick={() => handleToggleTabs('Squads')} >Squads</li>
                                        <li className={activeTab === 'Stats' ? 'active' : ''} onClick={() => handleToggleTabs('Stats')} >Stats</li>
                                        <li className={activeTab === 'News' ? 'active' : ''} onClick={() => handleToggleTabs('News')} >News</li>
                                        <li className={activeTab === 'Venue' ? 'active' : ''} onClick={() => handleToggleTabs('Venue')} >Venue</li>
                                    </ul>
                                </div>
                                {activeTab === 'Overview' ?
                                    <SeriesOverview seriesDetail={seriesDetail}></SeriesOverview>
                                    :
                                    activeTab === 'Matches' ?
                                        <SeriesMatches seriesDetail={seriesDetail}></SeriesMatches>
                                        :
                                        activeTab === 'Point Table' ?
                                            <SeriesPointTable seriesDetail={seriesDetail}></SeriesPointTable>
                                            :
                                            activeTab === 'Squads' ?
                                                <SeriesSquads seriesDetail={seriesDetail}></SeriesSquads>
                                                :
                                                activeTab === 'Stats' ?
                                                    <SeriesStats seriesDetail={seriesDetail}></SeriesStats>
                                                    :
                                                    activeTab === 'News' ?
                                                        <SeriesNews seriesDetail={seriesDetail}></SeriesNews>
                                                        :
                                                        activeTab === 'Venue' ?
                                                            <SeriesVenue seriesDetail={seriesDetail}></SeriesVenue>
                                                            : null}
                            </div>
                        }
                       
                    </div>
                </div>
            </section>
            </MobileView>
            <Footer></Footer>
        </>
    )
}

export default SeriesDetail