import { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import constant from "../../../Component/Services/constant";
import { useNavigate } from "react-router-dom";
const SeriesVenue = ({ seriesDetail }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    useEffect(() => {
        if (seriesDetail.series_cid) {
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }, [seriesDetail.series_cid]);

    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };

    const handleLink = (newsId) => {
        navigate(`/venue-detail/${newsId}`);
    };
    return (
        <>
            {loading ?
                <div>
                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                    </div>
                    <div className="row g-3">
                        {Array.from({ length: 4 }).map((_, index) => (
                            <div className="col-lg-6" key={index}>
                                <div className="blog-list">
                                    <div className="blog-list-media">
                                        <a href="javscript:void(0)">
                                            <Skeleton width={500} height={500} />
                                        </a>
                                    </div>
                                    <div className="blog-list-content">
                                        <h2 className="title"><Skeleton width={350} height={20} /></h2>
                                        <p className="tx-gray-500"><Skeleton width={420} height={15} /><Skeleton width={350} height={15} /></p>
                                        <ul className="blog-list-meta">
                                            <li><Skeleton width={100} height={15} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                :
                seriesDetail && seriesDetail.series_venues && seriesDetail.series_venues.data.length > 0 ?
                    <div>
                        <div className="section-title">
                            <h5 className="mb-0">Stadium Match Venue</h5>
                        </div>
                        <div className="row g-3">
                            {seriesDetail.series_venues.data.slice(0, 2).map((news, index) => (
                                <div className="col-lg-6" key={index} onClick={(e) => handleLink(news.news_slug)}>
                                    <div className="blog-list">
                                        <div className="blog-list-media">
                                            <a href="javscript:void(0)">
                                                <img src={news.image} onError={handleImageError} alt={news.news_title}></img>
                                            </a>
                                        </div>
                                        <div className="blog-list-content">
                                            <h2 className="title">{news.name}</h2>
                                            <ul className="blog-list-meta">
                                                <li><i className="ri-map-pin-line"></i> {news.place}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    : <div className="section-gap-large">
                    <div className="text-center">
                        <img src="/img/nomatch-preview.png" alt="No Data" className="fw700" style={{width:'250px'}}/>
                        <h6>No Data Found !</h6>
                    </div>
                    </div>
            }
        </>

    )
}

export default SeriesVenue