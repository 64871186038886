import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import HomeMatchSection from "../../Component/Elements/home_match_section";
import TrendingPlayer from "../../Component/Elements/trending_player";
import LatestNews from "../../Component/Elements/latest_news";
import CurrentSeries from "../../Component/Elements/current_series";
import Ranking from "../../Component/Elements/ranking";
import UpcomingMatches from "../../Component/Elements/upcoming_matches";
import LatestUpdate from "../../Component/Elements/latest_update";
import { BrowserView, MobileView} from 'react-device-detect';

const Home = () => {
    return (<>
    <BrowserView>
        <Header></Header>
        <HomeMatchSection></HomeMatchSection>
        <section className="section-gap-small match-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <LatestNews />
                        <TrendingPlayer />
                    </div>
                    <div className="col-lg-6">
                        <LatestUpdate /> 
                    </div>
                    <div className="col-lg-3">
                        <CurrentSeries />
                        <UpcomingMatches />
                        <Ranking />
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </BrowserView>
    <MobileView>
    <Header></Header>
    <HomeMatchSection></HomeMatchSection>
    <div className="p-15 pt-0">
    <CurrentSeries />
    <LatestNews />
    <UpcomingMatches />
    <TrendingPlayer />
    <Ranking />
    </div>
    <Footer></Footer>
  
    </MobileView></>)
}

export default Home