import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../Component/Services/apiservices";
import constant from "../../../Component/Services/constant";
import { formatPublishDate, formatTimeFromNow } from "../../../Component/Services/moment";
import { useEffect, useRef, useState } from "react";
import Skeleton from 'react-loading-skeleton'
const SeriesNews = ({ seriesDetail }) => {
    const didMountRef = useRef(true);
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    useEffect(() => {
        if (seriesDetail.series_cid) {
            latestNews();
        }
    }, [seriesDetail.series_cid]);

    const latestNews = () => {
        const dataString = {
            series_cid: seriesDetail.series_cid
        }
        ApiService.postData('series/seriesNews', dataString).then((res) => {
            if (res.status === 'success') {
                setNewsData(res.resData);
                setLoading(false);
            }
        });
    };

    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };

    const handleLink = (newsId) => {
        navigate(`/news-detail/${newsId}`);
    };
    return (
        <div>
            {loading ?
                <>
                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                    </div>
                    <div className="row g-3">
                        {Array.from({ length: 2 }).map((_, index) => (
                            <div className="col-lg-6" key={index}>
                                <div className="blog-list">
                                    <div className="blog-list-media">
                                        <a href="javscript:void(0)">
                                            <Skeleton width={500} height={500} />
                                        </a>
                                    </div>
                                    <div className="blog-list-content">
                                        <h2 className="title"><Skeleton width={350} height={20} /></h2>
                                        <p className="tx-gray-500"><Skeleton width={420} height={15} /><Skeleton width={350} height={15} /></p>
                                        <ul className="blog-list-meta">
                                            <li><Skeleton width={100} height={15} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {Array.from({ length: 3 }).map((_, index) => (
                            <div className="col-lg-12" key={index}>
                                <div className="blog-list blog-list-style-1">
                                    <div className="blog-list-media">
                                        <a href="javscript:void(0)">
                                            <Skeleton width={300} height={300} />
                                        </a>
                                    </div>
                                    <div className="blog-list-content">
                                        <h2 className="title"><Skeleton width={400} height={20} /></h2>
                                        <p className="tx-gray-500"><Skeleton width={500} height={15} /><Skeleton width={450} height={15} /></p>
                                        <ul className="blog-list-meta">
                                            <li><Skeleton width={100} height={15} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
                :
                newsData && newsData.length > 0 ?
                    <>
                        <div className="section-title">
                            <h5 className="mb-0">Latest Series News</h5>
                        </div>
                        <div className="row g-3">
                            {newsData.slice(0, 2).map((news, index) => (
                                <div className="col-lg-6" key={index} onClick={(e) => handleLink(news.news_slug)}>
                                    <div className="blog-list">
                                        <div className="blog-list-media">
                                            <a href="javscript:void(0)">
                                                <img src={news.news_image} onError={handleImageError} alt={news.news_title}></img>
                                            </a>
                                        </div>
                                        <div className="blog-list-content">
                                            <h2 className="title">{news.news_title}</h2>
                                            <p className="tx-gray-500">{news.news_description}</p>
                                            <ul className="blog-list-meta">
                                                <li><i className="ri-calendar-line"></i> {formatPublishDate(news.news_publish_date)}  |  {formatTimeFromNow(news.news_publish_date)}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {newsData.slice(2).map((news, index) => (
                                <div className="col-lg-12" key={index} onClick={(e) => handleLink(news.news_slug)}>
                                    <div className="blog-list blog-list-style-1">
                                        <div className="blog-list-media">
                                            <a href="javscript:void(0)">
                                                <img src={news.news_image} onError={handleImageError} alt={news.news_title}></img>
                                            </a>
                                        </div>
                                        <div className="blog-list-content">
                                            <h2 className="title">{news.news_title}</h2>
                                            <p className="tx-gray-500">{news.news_description}</p>
                                            <ul className="blog-list-meta">
                                                <li><i className="ri-calendar-line"></i> {formatPublishDate(news.news_publish_date)}  |  {formatTimeFromNow(news.news_publish_date)}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                    : <div className="section-gap-large">
                    <div className="text-center">
                        <img src="/img/nomatch-preview.png" alt="No Data" className="fw700" style={{width:'250px'}}/>
                        <h6>No Data Found !</h6>
                    </div>
                    </div>
            }
        </div>
    )
}

export default SeriesNews